import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button, Switch, Input, Tooltip, Popover, Icon } from 'antd';
import { getGoodsList, deleteGoods, putGoods, getCategory } from "../../action/goodAction";
import { getPartner, UpdateShopMessage } from "../../action/authAction"
import intl from 'react-intl-universal';
import silverImg from "../../img/member/silver.png"
import goldImg from "../../img/member/gold.png"
import diamondImg from "../../img/member/diamond.png"
import memberImg from "../../img/member/member.png"

const { Search } = Input;
let currency_symbol = sessionStorage.getItem("currency_symbol")


const fileIcon = () => (
    <svg t="1672985602944" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1282" width="16" height="16"><path d="M143 398v415h737V398H143z m-80-48c0-17.673 14.327-32 32-32h833c17.673 0 32 14.327 32 32v511c0 17.673-14.327 32-32 32H95c-17.673 0-32-14.327-32-32V350z" fill="#515151" p-id="1283"></path><path d="M296.657 581.044a8 8 0 0 1 0-11.314l45.255-45.255a8 8 0 0 1 11.313 0l125.158 125.158 195.162-195.162a8 8 0 0 1 11.313 0l45.255 45.255a8 8 0 0 1 0 11.314L489.697 751.456c-6.248 6.249-16.379 6.249-22.627 0L296.657 581.044zM225 199a8 8 0 0 1 8-8h560a8 8 0 0 1 8 8v56a8 8 0 0 1-8 8H233a8 8 0 0 1-8-8v-56zM352 72a8 8 0 0 1 8-8h303a8 8 0 0 1 8 8v56a8 8 0 0 1-8 8H360a8 8 0 0 1-8-8V72z" fill="#515151" p-id="1284"></path></svg>
)

class GoodList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: "",
            meta: "",
            page: 1,
            CategoryData: "",
            searchValue: "",
            url: "",
            url_1:"",
            url_2:"",
            url_3:"",
            url_4:"",

            is_discount: false,
            is_coupon: false,
            is_open_recommend: false,
            is_authorization_code: false,

            disable_language:true,
        };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '3%',
                align: "center",
            },
            {
                title: intl.get('goods.list.img'),
                width: '5%',
                align: "center",
                render: (res) => {
                    return (
                        <img style={{ width: "50px", height: "50px", objectFit: 'cover' }} src={res.image} alt="" />
                    )
                }
            },
            {
                title: intl.get('goods.list.name'),
                //dataIndex: 'name',
                width: '10%',


                 render: (res) => {



                       
                       var strImg = ""; 
                        if(res.spuMemberPrice.data.length > 0){
                            res.spuMemberPrice.data.map((item, index) => { 
                                 if(item.member_level_name == 'silver'){
                                        strImg =   <img src={memberImg} alt="avatar" style={{ width: '20px',paddingRight:"5px" }} /> ;
                                }

                                if(item.member_level_name == 'gold'){
                                        strImg =   <img src={memberImg} alt="avatar" style={{ width: '20px',paddingRight:"5px" }} /> ;
                                }

                                if(item.member_level_name == 'diamond'){
                                        strImg =   <img src={memberImg} alt="avatar" style={{ width: '20px',paddingRight:"5px" }} /> 
                                }

                            })
                        }




                    return (<div>
                        
                        <span>{strImg}</span><span style={{ paddingRight:"5px"}}>{res.name}</span>
                     </div>)
                }

 

                // align: "center",
                // filters: [
                //     { text: '主食和粥', value: 60 },
                //     { text: '小吃零食', value: 63 },
                //     { text: '海鲜', value: 62 },
                //     { text: '空分类', value: 119 },
                //     { text: '素菜', value: 59 },
                //     { text: '荤菜', value: 61 },
                //     { text: '酒水饮料', value: 65 }
                // ],
            },




            {
                title: intl.get('ad.form.cate'),
                dataIndex: ["category", "name"],
                width: '10%',
                align: "center",

            },

            // {
            //     title: intl.get('goods.list.attribute'),
            //     width: '5%',
            //     align: "center",
            //     render: (record) => {
            //         let value = ""
            //         let spuSku = record.spuSku.data.length
            //         let spuGoodsAnnexType = record.spuGoodsAnnexType.data.length
            //         if (spuSku > 0) {
            //             value = "sku"
            //         }

            //         if (spuGoodsAnnexType > 0) {
            //             value = "addl"
            //         }
            //         if (spuSku > 0 && spuGoodsAnnexType > 0) {
            //             value = "sku,addl"
            //         }
            //         return value
            //     }
            // },
            {
                title: intl.get('goods.list.price'),
                dataIndex: 'show_price',
                width: '8%',
                align: "center",
                render: (record) => {
                    return <span>{currency_symbol}{record}</span>
                }
            },

            {
                title: intl.get('goods.list.isOk'),
                width: '5%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.state === 1} onChange={() => {
                            putGoods({ "state": record.state === 1 ? "0" : "1" }, record.id).then(res => {
                                this.GetgoodData(this.state.page)
                            })
                        }} />
                    )
                }
            },
            // {
            //     title: intl.get('goods.list.selected'),
            //     width: '10%',
            //     render: (record) => {
            //         return (
            //             <Switch checked={record.recommend === 1} onChange={() => {
            //                 putGoods({ "recommend": record.recommend === 1 ? "0" : "1" }, record.id).then(res => {
            //                     this.GetgoodData(this.state.page)
            //                 }).catch(error => {
            //                     // console.log(error.response, "11")
            //                 })
            //             }} />
            //         )
            //     }
            // },


            {
                title: intl.get('goods.list.isShow'),
                width: '5%',
                align: "center",
                render: (record) => {
                    let { is_authorization_code } = this.state
                    return (
                        <Switch disabled={!is_authorization_code} checked={record.is_show === 1} onChange={() => {
                            putGoods({ "is_show": record.is_show === 1 ? "0" : "1" }, record.id).then(res => {
                                this.GetgoodData(this.state.page)
                            }).catch(error => {
                                // console.log(error.response, "11")
                            })
                        }} />
                    )
                }
            },


            {
                title: <Tooltip title="如果该语言没有激活，则在当前语言环境下，不显示该条目" > 语言<Icon type="question-circle" style={{ marginLeft: "5px" }} /> </Tooltip>,
                width: '5%',
                align: "center",
                render: (record) => {
                    let { is_authorization_code } = this.state
                    return (
                        <Switch disabled={!is_authorization_code} 
                            checked={record.disable_language == 0 } 
                            onChange={() => {
                            putGoods({ "disable_language": record.disable_language === 1 ? "0" : "1" }, record.id)
                                .then(res => {
                                    this.GetgoodData(this.state.page)
                                }).catch(error => {
                                    // console.log(error.response, "11")
                                })
                        }} />
                    )
                }
            },




            {
                title: intl.get('goods.list.sort'),
                dataIndex: 'sort',
                width: '5%',
                align: "center",
            },
            {
                title: intl.get('seat.table.operation'),
                dataIndex: 'operation',
                width: '5%',
                align: "center",
                render: (text, record) => {
                    return <Popover
                        style={{ width: 500 }}
                        content={
                            <div style={{ display: "flex" }}>
                                <Tooltip title={intl.get('seat.table.btn.edit')}>
                                    <Button
                                        style={{ marginRight: "5px" }}
                                        shape="circle"
                                        icon="edit"
                                        onClick={() => {
                                            this.props.history.push("/EditGood/" + record.id)
                                        }} />
                                </Tooltip>
                                {
                                    record.is_not_update === 0 &&
                                    <Popconfirm title={intl.get('home.table.operation.btn.file_horder')} onConfirm={() => this.handleDelete(record)}>
                                        <Tooltip title={intl.get('home.table.operation.btn.file')}>
                                            <Button
                                                style={{ margin: "0px 5px" }}
                                                shape="circle"
                                            >
                                                <Icon component={fileIcon} style={{ marginTop: "5px" }} />
                                            </Button>
                                        </Tooltip>
                                    </Popconfirm>
                                }
                                
                                {/*
                                <Tooltip title={intl.get('nav.goodsManagement.promotionalgoods.coupon')}>
                                    <Button
                                        style={{ marginRight: "5px" }}
                                        shape="circle"
                                        icon="tags"
                                        onClick={() => {
                                            this.props.history.push("/couponList?spu_id=" + record.id)
                                        }} />
                                </Tooltip>*/}

                                        
                            </div>
                        }
                        trigger="hover"
                    >
                        <Button
                            style={{ marginRight: "5px" }}
                            shape="circle"
                            icon="more"
                        />
                    </Popover>
                },
            },

        ]
    }

    async componentDidMount() {
        getCategory().then(res => {
            let data = res.data.data;
            let list = [];
            for (let i in data) {
                list.push({
                    text: data[i].name,
                    value: data[i].id,
                })
            }
        })
        this.GetgoodData(1)
    }

    async GetgoodData(num) {
        let res = await getGoodsList(num, this.state.url, this.state.searchValue)
        let shopData = await getPartner()
        this.setState({
            is_discount: shopData.data.is_discount === 1,
            is_coupon: shopData.data.is_coupon === 1,
            is_open_recommend: shopData.data.is_open_recommend === 1,
            is_authorization_code: shopData.data.is_authorization_code === 1,
            is_open_goods_annex: shopData.data.is_open_goods_annex === 1,
            is_sku: shopData.data.is_sku === 1,
            data: res.data.data,
            meta: res.data.meta.pagination
        })
    }

    async handleDelete(data) {
        await deleteGoods(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }
    HandleOpenSelect = (data) => {
        UpdateShopMessage(data).then(res => {
            this.GetgoodData(1)
        })
    }


    render() {
        const { data, meta, url, url_1,url_2,url_3,url_4, is_discount, is_coupon, is_open_goods_annex, is_sku, is_open_recommend, is_authorization_code } = this.state
        if (data) {
            return (
                <div>

                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                        <div>
                            <Button
                                style={{ backgroundColor: url === "" ? "#58924d" : "", color: url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "",
                                        url_1:"",
                                        url_2:"",
                                        url_3:"",
                                        url_4:"",
                                    }, () => {
                                        this.GetgoodData(1)
                                    })
                                }}
                            >
                                {intl.get('home.table.useStatus.all')}
                            </Button>


                             {
                                is_open_recommend &&
                                <Button
                                    style={{ marginLeft: "5px", backgroundColor: url_1 === "&recommend=1" ? "#58924d" : "", color: url_1 === "&recommend=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                    onClick={() => {
                                        this.setState({
                                            url: "&recommend=1" + url_2 +  url_3   + url_4, 
                                            url_1:"&recommend=1"
                                        }, () => {
                                            this.GetgoodData(1)
                                        })
                                    }}
                                >
                                    {intl.get('nav.event.selected')}
                                </Button>
                            }



                           


                            {
                                is_sku &&
                                <Button
                                    style={{ marginLeft: "5px", backgroundColor: url_1 === "&no_sku=0" ? "#58924d" : "", color: url_1 === "&no_sku=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                    onClick={() => {
                                        this.setState({
                                            url: "&no_sku=0"+ url_2 +  url_3   + url_4, 
                                            url_1:"&no_sku=0"
                                        }, () => {
                                            this.GetgoodData(1)
                                        })
                                    }}
                                >
                                    {intl.get('nav.goodsManagement.sku_goods')}
                                </Button>
                            }



                            {
                                is_open_goods_annex &&
                                <Button
                                    style={{ marginLeft: "5px", backgroundColor: url_1 === "&no_annex_type=0" ? "#58924d" : "", color: url_1 === "&no_annex_type=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                    onClick={() => {
                                        this.setState({
                                            url: "&no_annex_type=0"+ url_2 +  url_3   + url_4, 
                                            url_1:"&no_annex_type=0"
                                        }, () => {
                                            this.GetgoodData(1)
                                        })
                                    }}
                                >
                                    {intl.get('nav.goodsManagement.additional')}
                                </Button>
                            }



                             {
                                is_discount &&
                                <Button
                                    style={{ marginLeft: "5px", backgroundColor: url_1 === "&is_discount=1" ? "#58924d" : "", color: url_1 === "&is_discount=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                    onClick={() => {
                                        this.setState({
                                            url: "&is_discount=1"+ url_2 +  url_3  + url_4 , 
                                             url_1:"&is_discount=1"
                                        }, () => {
                                            this.GetgoodData(1)
                                        })
                                    }}
                                >
                                    {intl.get('nav.goodsManagement.promotionalgoods')}
                                </Button>

                            }




                            {
                                is_coupon &&
                                <Button
                                    style={{ marginLeft: "5px", backgroundColor: url_1 === "&no_coupon=0" ? "#58924d" : "", color: url_1 === "&no_coupon=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                    onClick={() => {
                                        this.setState({
                                            url: "&no_coupon=0"+ url_2 +  url_3   + url_4, 
                                             url_1:"&no_coupon=0"
                                        }, () => {
                                            this.GetgoodData(1)
                                        })
                                    }}
                                >
                                    {intl.get('nav.goodsManagement.coupon_goods')}
                                </Button>
                            }


                            {
                                is_coupon &&
                                <Button
                                    style={{ marginLeft: "5px", backgroundColor: url_1 === "&no_member=0" ? "#58924d" : "", color: url_1 === "&no_member=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                    onClick={() => {
                                        this.setState({
                                            url: "&no_member=0"+ url_2 +  url_3   + url_4, 
                                             url_1:"&no_member=0"
                                        }, () => {
                                            this.GetgoodData(1)
                                        })
                                    }}
                                >
                                   会员商品
                                </Button>
                            }

                           


                        </div>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            {
                                is_authorization_code &&
                                <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                                    <span>{intl.get("goods.list.selected")}：</span>
                                    <Switch
                                        checked={is_open_recommend}
                                        onChange={() => {
                                            this.setState({
                                                is_open_recommend: !is_open_recommend
                                            }, () => {
                                                this.HandleOpenSelect({ "is_open_recommend": this.state.is_open_recommend ? "1" : "0" })
                                            })
                                        }} />
                                </div>
                            }

                            <Tooltip title={intl.get('nav.goodsManagement.goods.isdeletegoodslist')}>
                                <Button
                                    style={{ marginRight: "10px" }}
                                    icon="project"
                                    onClick={() => {
                                        this.props.history.push("/isdeletegoodsList")
                                    }} />
                            </Tooltip>
                            <Search
                                style={{ width: 250, marginRight: "10px" }}
                                placeholder={intl.get("goods.list.name.horder")}
                                enterButton={'search'}
                                onSearch={(val) => {
                                    this.setState({
                                        searchValue: val
                                    }, () => {
                                        this.GetgoodData(1)
                                    })
                                }}
                            />
                            <Button type="primary"
                                onClick={() => this.props.history.push("/newGoods")}
                            >
                                {intl.get('nav.goodsManagement.goods.newGood')}
                            </Button>
                        </div>
                    </div>




                     <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0px" }}> 
                        <div style={{ marginBottom: "5px" }}> 

                            <Button
                                style={{ marginRight: "5px", backgroundColor:  "#58924d"  , color: "#fff"   }}
                                onClick={() => {
                                    this.setState({
                                        url: ""
                                    }, () => {
                                        //this.HandleGetData(1, this.state.url)
                                    })
                                }}>
                                上架
                            </Button>

                             <Button
                                style={{ marginRight: "5px", backgroundColor: url_2 === "&state=1" ? "#58924d" : "", color: url_2 === "&state=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&state=1" + url_1 +  url_3  + url_4 ,
                                        url_2:"&state=1",
                                         
                                    }, () => {
                                        this.GetgoodData(1, this.state.url)
                                    })
                                }}>
                                已上架
                            </Button>



                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_2 === "&state=0" ? "#58924d" : "", color: url_2 === "&state=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&state=0" + url_1 +  url_3  + url_4 , 
                                        url_2:"&state=0",
                                        
                                    }, () => {
                                        this.GetgoodData(1, this.state.url)
                                    })
                                }}>
                                已下架
                            </Button> 
                        </div>
                    </div>




                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0px" }}> 
                        <div style={{ marginBottom: "5px" }}> 

                            <Button
                                style={{ marginRight: "5px", backgroundColor:  "#58924d"  , color: "#fff"   }}
                                onClick={() => {
                                    this.setState({
                                        url: ""
                                    }, () => {
                                        //this.HandleGetData(1, this.state.url)
                                    })
                                }}>
                                显示
                            </Button>

                             <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&is_show=1" ? "#58924d" : "", color: url_3 === "&is_show=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_show=1"  + url_1 +  url_2   + url_4, 
                                        url_3:"&is_show=1",
                                         
                                    }, () => {
                                        this.GetgoodData(1, this.state.url)
                                    })
                                }}>
                                已显示
                            </Button>



                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&is_show=0" ? "#58924d" : "", color: url_3 === "&is_show=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_show=0"    + url_1 +  url_2  + url_4, 
                                         url_3:"&is_show=0",
                                        
                                    }, () => {
                                        this.GetgoodData(1, this.state.url)
                                    })
                                }}>
                                未显示
                            </Button> 
                        </div>
                    </div>


                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}> 
                        <div style={{ marginBottom: "5px" }}> 

                            <Button
                                style={{ marginRight: "5px", backgroundColor:  "#58924d"  , color: "#fff"   }}
                                onClick={() => {
                                    this.setState({
                                        url: ""
                                    }, () => {
                                        //this.HandleGetData(1, this.state.url)
                                    })
                                }}>
                                语言
                            </Button>

                             <Button
                                style={{ marginRight: "5px", backgroundColor: url_4 === "&is_disable_language=1" ? "#58924d" : "", color: url_4 === "&is_disable_language=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_disable_language=1"  + url_1 +  url_2  + url_3, 
                                        url_4:"&is_disable_language=1",
                                         
                                    }, () => {
                                        this.GetgoodData(1, this.state.url)
                                    })
                                }}>
                                已激活
                            </Button>



                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_4 === "&is_disable_language=0" ? "#58924d" : "", color: url_4 === "&is_disable_language=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_disable_language=0"    + url_1 +  url_2  + url_3, 
                                        url_4:"&is_disable_language=0",
                                        
                                    }, () => {
                                        this.GetgoodData(1, this.state.url)
                                    })
                                }}>
                                未激活
                            </Button> 
                        </div>
                    </div>









                    <Table
                        rowKey={record => record.id}
                        dataSource={data}
                        columns={this.columns}
                        // scroll={{ x: 1000 }}
                        pagination={{
                            pageSize: meta.per_page,
                            total: meta.total
                        }}
                        onChange={(a, b, c) => {
                            this.setState({
                                page: a.current
                            }, () => this.GetgoodData(a.current))
                        }}
                        className="waperBox"
                    />
                </div>

            );
        } else {
            return (<div />)
        }

    }
}

export default withRouter(Form.create()(GoodList))

