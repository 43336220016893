import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, InputNumber, Button, Select, Radio, DatePicker, message, Switch, Upload, Icon, Tooltip, Checkbox, Popconfirm } from 'antd';
import moment from 'moment';
import { getNoSkuGoodsList, AddCoupon } from "../../action/goodAction";
import { GetConfigSet, getPartner, getLangList } from "../../action/authAction";
import intl from 'react-intl-universal';
import BraftEditor from 'braft-editor'
import { getLangType } from "../../util/function";
const { Option } = Select;
const { RangePicker } = DatePicker;
let currency_symbol = sessionStorage.getItem("currency_symbol")

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}
function beforeUpload(file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.destroy()
        message.error(intl.get('file.size.placehorder'));
    }
    return isLt1M;
}

class NewCoupon extends React.Component {
    state = {
        value: "",
        SearchData: [],
        spu_id: "",
        discount_type: "fixed",
        image_type: "default",
        coupon_type: "0", //0 商品goods  1 店铺  shop
        price_type: "price",
        date: "",
        goods: "",
        imageUrl: "",
        logo_file: "",
        min_pay_fee_on_online: "",
        is_eatmoon_show: false,
        state: false,
        disable_language: true,
        is_disable_language: false,
        typeData: [
            // {
            //     id: 0,
            //     name: intl.get("nav.goodsManagement.promotionalgoods.inside")
            // },
            {
                id: 1,
                name: "N"  //intl.get("nav.goodsManagement.promotionalgoods.external_coupon")
            },

            {
                id: 2,
                name: "R" //intl.get("nav.goodsManagement.promotionalgoods.external_coupon")
            },


            {
                id: 3,
                name: "SR"
            },


            {
                id: 4,
                name: "SSR"
            },



            // {
            //     id: 2,
            //     name: intl.get("nav.goodsManagement.promotionalgoods.mysterious")
            // }
        ],
        positionData: [
            {
                value: 1,
                label: intl.get("nav.goodsManagement.promotionalgoods.on-line"),
                disabled: false
            },
            {
                value: 0,
                label: intl.get("nav.goodsManagement.promotionalgoods.Offline")
            },

        ],
        operation_type: "",
        editorState: BraftEditor.createEditorState(""),
    };

    async componentDidMount() {

        let configData = await GetConfigSet()
        let res = await getPartner()
        let data = res.data
        let language = await getLangList()
        let languageData = language.data.data
        let languageList = []
        for (let i in languageData) {
            if (languageData[i].is_use === 1) {
                languageList.push(languageData[i])
            }
        }
        let is_authorization_code = data.is_authorization_code === 1
        let positionData = [...this.state.positionData]
        positionData[0].disabled = is_authorization_code ? false : true
        this.setState({
            min_pay_fee_on_online: configData.data.data.find(el => el.key === "min_pay_fee_on_online").value,
            is_eatmoon_show: data.is_eatmoon_show == 1 ? true : false,
            positionData: positionData,
            is_disable_language: languageList.length === 1 ? true : false,
            spu_quantity: data.package.spu_quantity, 
            editorState : BraftEditor.createEditorState(intl.get("nav.goodsManagement.coupon.detail_tips")  )  //初始化编辑器内容
        })
    }

    getNowFormatDate = () => {
        var date = new Date();
        var seperator1 = "-";
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        var currentdate = year + seperator1 + month + seperator1 + strDate;
        return currentdate;
    }


    handleSubmit = (e) => {
        e.preventDefault();
        let ha = this.getNowFormatDate()
        this.props.form.validateFieldsAndScroll((err, values) => {
            let formData = new FormData()
            let { spu_id, discount_type, price_type, logo_file, state, editorState, coupon_type, operation_type, image_type } = this.state
            if (coupon_type === "0") {
                values.spu_id = spu_id
            } else {
                values.spu_id = "0"
            }

            values.coupon_type = coupon_type
            values.discount_type = discount_type
            values.price_type = price_type
            values.image_type = image_type === "default" ? "0" : "1"
            values.disable_language = this.state.disable_language ? "0" : "1"
            values.operation_type = operation_type.toString()
            values.state = state ? "1" : "0"
            //values.type = "1"
            values.is_show = "1"
            values.detail = editorState && editorState.toHTML()
            values.start_at = values.date ? moment(values.date[0]).format("YYYY-MM-DD") + " 00:00:00" : ha + " 00:00:00"
            values.end_at = values.date ? moment(values.date[1]).format("YYYY-MM-DD") + " 23:59:59" : ha + " 23:59:59"
            values.discount_on_percentage = parseInt(values.discount_on_percentage) / 100
            delete values.good_image
            delete values.goods_rules
            delete values.date

            if (values.quota) {
                if (parseInt(values.quota) > parseInt(values.stock)) {
                    message.error(intl.get("nav.goodsManagement.promotionalgoods.quota_cannot_stock"))
                    return false
                }
            }

            if (!err) {
                if (values.spu_id) {
                    for (let i in values) {
                        if (values[i]) {
                            formData.append(i, values[i])
                        }
                    }
                    logo_file && formData.append("image_file", logo_file)
                    AddCoupon(formData).then(res => {
                        this.props.history.push("/couponList")
                    })
                } else {
                    message.error(intl.get('nav.goodsManagement.promotionalgoods.selectError'))
                }
            }


        });
    };

    onChange = e => {
        this.setState({
            value: e
        });
    };

    onSelectSearch = (val) => {
        if (val.length > 0) {
            getNoSkuGoodsList(1, val).then(res => {
                this.setState({
                    SearchData: res.data.data
                })
            })
        }
    }

    handleChange = info => {
        let isOK = beforeUpload(info.file)
        if (isOK) {
            if (info.file) {
                getBase64(info.file, (imageUrl, img) =>
                    this.setState({
                        imageUrl,
                        logo_file: img
                    }),
                );
            }
        }
    };

    checkPsd2(rule, value, callback) {
        let price = this.state.goods.price;
        if (price) {
            if (this.accMul(price, value) / 100 < parseInt(this.state.min_pay_fee_on_online)) {
                callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.minimumOrder")));
            } else {
                callback();
            }
        }

    }
    checkStock(rule, value, callback) {

        let stock = this.state.data && this.state.data.stock;
        if (stock && value && parseInt(stock) > parseInt(value)) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.stock_error")));
        } else {
            callback();
        }
    }
    checkFullPrice(rule, value, callback) {
        let discount_on_fixed = this.props.form.getFieldValue('discount_on_fixed')
        let notOk = parseFloat(value) < discount_on_fixed + 1
        if (notOk) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.full_price_error")));
        } else {
            callback();
        }
    }
    // 除以
    accDiv(arg1, arg2) {
        let t1 = 0,
            t2 = 0,
            r1, r2;
        try {
            t1 = arg1.toString().split(".")[1].length
        } catch (e) { }
        try {
            t2 = arg2.toString().split(".")[1].length
        } catch (e) { };
        r1 = Number(arg1.toString().replace(".", ""))
        r2 = Number(arg2.toString().replace(".", ""))
        return (r1 / r2) * Math.pow(10, t2 - t1);
    }
    // 乘以
    accMul(arg1, arg2) {

        let m = 0,
            s1 = arg1.toString(),
            s2 = arg2.toString();
        try {
            m += s1.split(".")[1].length
        } catch (e) { }
        try {
            m += s2.split(".")[1].length
        } catch (e) { }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    }
    // 减
    accSub(arg1, arg2) {
        let r1, r2, m, n;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2));
        //动态控制精度长度 
        n = (r1 >= r2) ? r1 : r2;
        return ((arg1 * m - arg2 * m) / m).toFixed(n);
    }



    render() {
        const { SearchData, discount_type, coupon_type, spu_id, image_type, price_type, goods, imageUrl, state, is_eatmoon_show, typeData, positionData, is_disable_language, disable_language, spu_quantity } = this.state;
        const { getFieldDecorator } = this.props.form;
        let discount_price_save = ""
        if (discount_type === "fixed") {
            discount_price_save = goods.price && this.accSub(goods.price, this.props.form.getFieldValue('discount_on_fixed') || 0)
        } else {
            discount_price_save = goods.price && parseFloat(this.accMul(parseFloat(goods.price), parseFloat(this.props.form.getFieldValue('discount_on_percentage')) / 100 || 1)).toFixed(2)
        }
        const formItemLayout = {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };
        const uploadButton = (
            <div>
                <Icon type='plus' />
                <div className="ant-upload-text">{intl.get('file.btn.name')}</div>
            </div>
        );
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView" style={{ minWidth: "1000px" }}>
                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.coupon_name')}
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: intl.get("nav.goodsManagement.promotionalgoods_name_error") }],
                        initialValue: goods && goods.name
                    })(<Input />)}
                </Form.Item>
                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.coupon_style')}
                >
                    <Radio.Group
                        // disabled
                        onChange={(e) => {
                            this.setState({
                                image_type: e.target.value
                            })
                        }}
                        value={image_type}>
                        <Radio value={"default"}>{intl.get("nav.goodsManagement.promotionalgoods.coupon_style_default")}</Radio>
                        <Radio value={"self"}>{intl.get("nav.goodsManagement.promotionalgoods.coupon_style_self")}</Radio>
                    </Radio.Group>
                </Form.Item>
                {
                    image_type === "self" &&
                    <Form.Item
                        label={<span>
                            {intl.get('nav.goodsManagement.promotionalgoods.coupon_style_img')}
                            <Tooltip title={intl.get("nav.goodsManagement.goods.prompt")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>

                        </span>}
                    >
                        <Upload
                            name="avatar"
                            accept=".jpg,.png,.jpeg"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={this.handleChange}
                        >
                            {!!imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </Form.Item>
                }

                <Form.Item
                    label="运营类型"
                >
                    <Radio.Group
                        onChange={(e) => {
                             
                            //商品优惠券
                            if(e.target.value == 0){
                                this.setState({
                                    coupon_type: e.target.value,
                                    discount_type:'fixed',
                                })
                            }else{ //店铺优惠券
                                this.setState({
                                    coupon_type: e.target.value,
                                    discount_type:'fixed',
                                })
                            } 

                        }}
                        value={coupon_type}>
                        <Radio value={"0"}>{intl.get("nav.goodsManagement.promotionalgoods.coupon_type_goods")}</Radio>
                        <Radio value={"1"}>{intl.get("nav.goodsManagement.promotionalgoods.coupon_type_shop")}</Radio>
                    </Radio.Group>
                </Form.Item>

                {
                    coupon_type === "0" &&
                    <Form.Item
                        label={<span>
                            {intl.get('ad.form.selectgoods')}
                            <Tooltip Tooltip title={intl.get("nav.goodsManagement.promotionalgoods.select_goods_word_tips")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>
                        }
                    >
                        {getFieldDecorator('goods_rules', {
                            rules: [{ required: true }],
                        })(
                            <Select
                                showSearch
                                placeholder={intl.get('ad.form.selectgoods')}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={this.onSelectSearch}
                                onChange={(e, value) => {
                                    this.props.form.setFieldsValue({ discount_on_fixed: "0" })
                                    this.props.form.setFieldsValue({ discount_on_percentage: "0" })
                                    this.setState({
                                        spu_id: value.props.value,
                                        goods: value.props.item,
                                    })
                                }}
                                notFoundContent={null}
                            >
                                {
                                    SearchData.length > 0 && SearchData.map((item, index) => {
                                        return <Option key={index} value={item.id} item={item}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        )}
                    </Form.Item>


                }



                <Form.Item
                    label="商品BDID"
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: false, message: intl.get("nav.goodsManagement.promotionalgoods_name_error") }],
                        initialValue: goods && goods.bdid
                    })(<Input disabled/>)}
                </Form.Item>




                

                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.coupon_discount_type')}
                >
                        <Radio.Group
                        onChange={(e) => {
                            this.setState({
                                discount_type: e.target.value
                            })
                        }}
                        value={discount_type}>


                        <Radio value={"fixed"}>{intl.get("nav.goodsManagement.promotionalgoods.quota_discount")}</Radio>

                        {
                            coupon_type === "0" &&
                            <Radio value={"percentage"}>{intl.get("nav.goodsManagement.promotionalgoods.percent_discount")}</Radio>
                        }

                    </Radio.Group>
                </Form.Item>



                {
                    discount_type === "fixed" ?
                        <Form.Item
                            label={intl.get('nav.goodsManagement.promotionalgoods.discount_on_fixed')}
                        >
                            {getFieldDecorator('discount_on_fixed', {
                                rules: [{ required: true, message: "" }],
                                initialValue: "0"
                            })(<InputNumber
                                formatter={value => `${currency_symbol}${value}`}
                                parser={value => value.replace(currency_symbol, '')}
                                style={{ width: "200px" }} />)}
                        </Form.Item> :

                        <Form.Item
                            label={intl.get('nav.goodsManagement.promotionalgoods.discount_on_percentage')}
                        >
                            {getFieldDecorator('discount_on_percentage', {
                                rules: [{ required: true, pattern: new RegExp(/^[1-99]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.enter_integer") }, { validator: (rule, value, callback) => { this.checkPsd2(rule, value, callback) } }],
                                initialValue: 100
                            })(<InputNumber formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')} min={1} max={99} style={{ width: "200px" }}
                            />)}
                        </Form.Item>
                }






                {
                    coupon_type === "0" && spu_id &&
                    <Form.Item
                        label={intl.get("nav.goodsManagement.promotionalgoods.discount_price")}
                    >
                        <span>
                            {currency_symbol}{
                                (goods && goods.rrp) || (goods && goods.price) ?
                                    discount_type === "fixed" ?
                                        goods ? this.accSub(goods.price, this.props.form.getFieldValue('discount_on_fixed') || 0) : 0 :
                                        goods && (parseFloat(this.accMul(parseFloat(goods.price), parseFloat(100-this.props.form.getFieldValue('discount_on_percentage')) || 1)) / 100).toFixed(2) : 0
                            }
                        </span>
                    </Form.Item>
                }









                {/*  是否上架
                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.OffTheShelf')}
                >
                    <Switch
                        checked={state}
                        onChange={() => {
                            this.setState({
                                state: !state
                            })
                        }}
                    />
                </Form.Item>
                */}




                {
                    !is_disable_language &&
                    <Form.Item label={<span>{intl.get('goods.list.disableLanguage')}</span>}>
                        <Switch checked={disable_language}
                            onChange={() => {
                                this.setState({
                                    disable_language: !disable_language
                                })
                            }} />
                    </Form.Item>
                }

                <Form.Item
                    label={<span>{intl.get('nav.goodsManagement.promotionalgoods.term_of_validity')}</span>}
                >
                    {getFieldDecorator('date', {
                        rules: [{ required: true, message: intl.get("nav.goodsManagement.promotionalgoods.timeError") }],
                    })(<RangePicker
                        format="DD/MM/YYYY"
                        disabledDate={(current) => {
                            return current < moment().endOf('day')

                        }}
                    />)}
                </Form.Item>


                <Form.Item
                    label={intl.get("nav.goodsManagement.promotionalgoods.marketing_type")}
                >
                    {getFieldDecorator('type', {
                        rules: [{ required: true }],
                        initialValue: 1
                    })(<Select
                         
                    >
                        {typeData && typeData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                    </Select>)}
                </Form.Item>



                {/*
                    <Form.Item
                        label={
                            <span>
                               显示概率
                                <Tooltip title="显示概率">
                                    <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                                </Tooltip>
                            </span>
                        }
                    >
                        {getFieldDecorator('show_probability', {
                            rules: [{ required: false, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }],
                            initialValue: 100
                        })(<Input type="number" />)}
                    </Form.Item>


                */}
                






                <Form.Item
                    label="使用类型"
                >
                    {getFieldDecorator('operation_type', {
                        rules: [{ required: true, message: intl.get("nav.goodsManagement.promotionalgoods.operationType_tips") }],
                        initialValue: [0]
                    })(
                        <Checkbox.Group
                            options={positionData}
                            onChange={(e) => {
                                console.log(e, "2323232")
                                this.setState({
                                    operation_type: e.length > 1 ? 2 : e[0]
                                })
                            }} />
                    )}
                </Form.Item>

                

                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.stock')}
                >
                    {getFieldDecorator('total_stock', {
                        rules: [{ required: true, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }, { validator: (rule, value, callback) => { this.checkStock(rule, value, callback) } }],
                        initialValue: spu_quantity
                    })(<InputNumber max={100} style={{ width: "100%" }} />)}
                </Form.Item>

                {/* <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.coupon_receive_count')}
                >
                    {getFieldDecorator('sale_quantity', {
                        rules: [{ required: true, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }],
                    })(<InputNumber style={{ width: "100%" }} />)}
                </Form.Item>
                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.coupon_use_count')}
                >
                    {getFieldDecorator('use_quantity', {
                        rules: [{ required: true, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }],
                    })(<InputNumber style={{ width: "100%" }} />)}
                </Form.Item> */}

                <Form.Item
                    label={
                        <span>
                            {intl.get('nav.goodsManagement.promotionalgoods.quota')}
                            <Tooltip title={intl.get("nav.goodsManagement.promotionalgoods.coupon_total_quota")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('quota', {
                        rules: [{ required: false, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }],
                        initialValue: 1
                    })(<Input type="number" />)}
                </Form.Item>
                {

                    coupon_type === "1" && discount_type === "fixed" &&
                    <Form.Item
                        label={<span>
                            {intl.get('nav.goodsManagement.promotionalgoods.full_price')}
                            <Tooltip title={intl.get("nav.goodsManagement.promotionalgoods.full_price_tips")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>}>
                        {getFieldDecorator('full_price', {
                            rules: [{ pattern: new RegExp(/^\d+(.\d{1,2})?$/), message: intl.get("nav.goodsManagement.promotionalgoods.full_price_error_tips") }, { validator: (rule, value, callback) => { this.checkFullPrice(rule, value, callback) } }],
                        })(<InputNumber
                            formatter={value => `${currency_symbol}${value}`}
                            parser={value => value.replace(currency_symbol, '')}
                            style={{ width: "100%" }} />)}
                    </Form.Item>
                }


                <Form.Item
                    label={intl.get('goods.list.sort')}
                >
                    {getFieldDecorator('sort', {
                        rules: [{ required: false }],
                        initialValue: 0
                    })(<Input type="number" />)}
                </Form.Item>


                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.coupon_detail')}
                >
                    <div>
                        <div style={{
                            border: "1px solid #dedede",
                        }}>
                            <BraftEditor
                                value={this.state.editorState}
                                language={Number(getLangType()) == 1 ? "zh" : "en"}
                                onChange={(editorState) => {
                                    this.setState({ editorState })
                                }} />
                        </div>


                        <div style={{     padding: "20px 0"  }}>
                        <p style={{ color: "#333", lineHeight: "20px" }}>提示：请使用“ $shop_name ”代替店铺名称</p>

                        <p style={{ color: "#333", lineHeight: "20px" }}>参考内容：{intl.get("nav.goodsManagement.coupon.detail_tips")}</p> 
                        </div>



                    </div>
                </Form.Item>

                
                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm
                        title={intl.get('seat.table.btn.cancel.placeHorder')}
                        onConfirm={() => {
                            this.props.history.goBack()
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            {intl.get('seat.table.btn.cancel')}
                        </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form >
        );
    }
}

export default withRouter(Form.create()(NewCoupon))
